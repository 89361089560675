<template>
    <b-container class="align-self-center">
        <b-row class="my-4 py-4">
            <b-col class="flex-center">
                <div>
                    <b-img :src="require('../../assets/img/logoblk.png')" fluid alt=""/>
                </div>
            </b-col>
        </b-row>
        <b-row class="justify-content-center align-items-center">
            <b-col cols="12" md="6">
                <form v-on:submit.prevent="login" class="auth-form">

                    <div class="form-group d-flex flex-column">
                        <label for="email" class="text-center">Email:</label>
                        <input v-model="email" id="email" type="email" class="form-control" name="email" required autofocus>
                    </div>

                    <div class="form-group d-flex flex-column">
                        <label for="password" class="text-center">Lozinka:</label>
                        <input id="password" type="password" class="form-control" name="password" required>
                    </div>

                    <div class="form-help d-flex justify-content-between">
                        <!--b-link :to="'/auth/forgot-password/' + email">
                            Zaboravili ste lozinku?
                        </b-link-->
                        <router-link to="/support">
                            Pomoć
                        </router-link>
                    </div>

                    <b-btn type="submit" variant="primary" class="mt-3 align-self-center w-50">
                        Prijava
                    </b-btn>
                </form>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'login',
        data(){
            return {
                email: ''
            }
        },
        methods: {
            login: function (e) {
                const formData = new FormData(e.target);
                axiOsta.post(api.login, formData).then(response => {
                    if(response.status === 200) {
                        this.$cookie.set('api_token', response.data.api_token);
                        this.$router.push('/')
                    }
                });
            }
        }
    }
</script>
